<template>
  <div v-if="!currentPath">
    <div class="header pop16">
      <span class="ama60">Aangespoeld</span>
      <p>

        Aangespoeld werd mede mogelijk gemaakt door het Engelbert van Bevervoorde-van Heyst Fonds. <br />
		Aangespoeld gaat over de mensen die vanaf 1958 de IWI-collectie bij elkaar brachten en daarmee de basis legden voor de Indische cultuur in Nederland. In de rechterkolom vormen ze een eregalerij van migranten.<br />
		Aangespoeld presenteert de (digitale) IWI-collectie via de levensverhalen van Indische migranten en hun nazaten. Samen schreven ze geschiedenis; de geschiedenis van de Indische cultuur in Nederland. Door een klik op het geboortejaar van een van onze cultuurdragers in de linkerkolom ontvouwt zich het leven van een individuele migrant. Met gemarkeerde teksten en aan te klikken foto's verbindt 'Aangespoeld' belangrijke momenten uit die levensverhalen met elkaar en met andere historische momenten uit de (koloniale) wereldgeschiedenis.<br />
		Aangespoeld is een werk-in-uitvoering. In het vervolgproject Aangespoeld 2 komt het accent te liggen op de zogenaamde 1,5e generatie, geboren vanaf ca 1930, kind in de oorlogen in Indië en tijdens de landverhuizing naar Europa, en hier in Nederland bekend van 'de pasar', de 'toko', de Indorock en vele andere manifestaties van de Indische cultuur.<br />
		Schrijft u ook mee?<br />
		Werkwijze voor uw eigen 'Aangespoeld'<br />
		<ol>
			<li>kies iemand die ooit in Nederland aanspoelde (uzelf, familie, bekende?)</li>
<li>maak zeven digitale afbeeldingen uit het leven van die migrant(e) </li>
<li>schrijf er onderschriften bij en vergeet de jaartallen niet </li>
<li>mail de tekst (maximaal 3x A4) en de 7 afbeeldingen naar <a href="mailto:info@iwi-nu.nl">info@iwi-nu.nl</a> </li>
<li>de redactie bewerkt uw bijdrage, natuurlijk in overleg met u  </li>
<li>het IWI voegt na uw akkoord uw pagina toe aan 'Aangespoeld' </li>
</ol>
<br />Eindredactie: Edy Seriese      </p>
    </div>
    <div class="data">
      <div class="records" v-for="(record, index) in records" :key="record.id">
        <div v-if="index & 1" class="p-d-flex p-ai-center">
          <div class="p-mr-2 articleimage">
            <img
              :src="
                `${$config.images.server}/images/${record.media}?height=671&fit=cover&background=ffffff`
              "
            />
          </div>
          <div class="article odd">
            <span class="ama35">{{ record.title }}</span>
            <span class="subtitle ama35"><br />({{ record.subtitle }})</span>
            <p class="short pop16">{{ record.shortdescription }}</p>
            <div class="more">
              <div>
                <router-link
                  class="more pop16"
                  :to="`/aangespoeld/${record.slug}`"
                  >Ga naar het verhaal&nbsp;&nbsp;<img
                    src="@/assets/images/rightarrow.svg"
                /></router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="p-d-flex p-ai-center">
          <div class="article">
            <span class="ama35">{{ record.title }}</span>
            <span class="subtitle ama35"><br />({{ record.subtitle }})</span>
            <p class="short pop16">{{ record.shortdescription }}</p>
            <div class="more">
              <div>
                <router-link
                  class="more pop16"
                  :to="`/aangespoeld/${record.slug}`"
                  >Ga naar het verhaal&nbsp;&nbsp;<img
                    src="@/assets/images/rightarrow.svg"
                /></router-link>
              </div>
            </div>
          </div>
          <div class="p-mr-2 articleimage even">
            <img
              :src="
                `${$config.images.server}/images/${record.media}?height=671&fit=cover&background=ffffff`
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <recordVue :path="currentPath" type="4" />
  </div>
</template>

<script>
import recordService from "../services/record_service"
import recordVue from "./record"

export default {
  components: {
    recordVue,
  },
  watch: {
    $route: "reload",
  },
  data() {
    return {
      records: {},
      currentPath: this.$router.currentRoute.value.params.recordTitle,
    }
  },
  recordservice: null,
  created() {
    this.recordservice = new recordService()
  },
  mounted() {
    this.recordservice.getAangespoeld().then((data) => (this.records = data))
  },
  methods: {
    reload() {
      this.currentPath = this.$router.currentRoute.value.params.recordTitle
    },
  },
}
</script>

<style scoped>
.header {
  width: 630px;
  position: absolute;
  top: 161px;
  left: 90px;
}
.data {
  position: sticky;
  margin-top: 600px;
  margin-left: auto;
  margin-right: auto;
  width: 1020px;
}
.records {
  margin-top: 50px;
  color: black;
}
.articleimage {
  height: 671px;
}
.article {
  background-color: #67a178;
  height: 400px;
  width: 630px;
  padding: 50px;
}
.odd {
  margin-left: -160px;
}
.even {
  margin-left: -130px;
  z-index: -1;
}
.short {
  height: 145px;
}
.more {
  color: white;
  background-color: #ef5e33;
  width: 267px;
  height: 50px;
  padding: 12px;
}
.more a {
  text-decoration: none;
}
</style>
